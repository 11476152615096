.container {
  display: block;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0;
}

.inputText {
  position: relative;
  display: block;
  border-radius: 0.25rem;
  border-style: none;
  color: wheat;
  background-color: var(--menu-background);
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0.25rem;
  text-align: center;
  font-family: var(--font-primary);
  font-size: 1.15rem;
  filter: opacity(0.75);
}

.inputText::placeholder {
  color: wheat;
}

input.inputText:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  filter: brightness(0.9);
}

input.inputText:focus {
  outline: none;
  border-color: var(--secondary);
  background-color: var(--secondary);
}
