.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.answerContainer {
  position: relative;
  display: block;
  height: 20vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--light);
  padding: 0.75rem;
  margin: auto;
  border-radius: 0.5rem;
  resize: none;
  border: none;
  color: var(--background);
  font-family: var(--font-secondary);
  font-size: larger;
  box-shadow: none;
  filter: brightness(90%);
}
.answerContainer:focus {
  outline: none !important;
}

.promptInputContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  gap: 0.25rem;
  padding: 0;
}

.promptsContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.promptExample {
  color: var(--light);
  background-color: var(--primary);
  padding: 0.25rem;
  margin: 0;
  font-size: small;
  font-weight: bold;
  font-family: var(--font-primary);
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  filter: brightness(85%);
  cursor: pointer;
}
.promptExample:hover {
  filter: brightness(105%);
}

.btnTool {
  position: relative;
  border-radius: 0.25rem;
  border-style: none;
  padding: 0.25rem;
  width: 2rem;
  height: 100%;
  cursor: pointer;
  background-color: var(--slate);
}

.sendButton,
.showInputBtn {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 3rem;
  height: 100%;
  border-radius: 0.25rem;
  border-style: none;
  text-align: center;
  padding: 0.5rem;
  background-color: transparent;
  filter: brightness(90%);
  cursor: pointer;
}
.showInputBtn {
  position: absolute;
  display: block;
  right: 3rem;
  width: 3rem;
  background-color: transparent;
  font-size: 1rem;
}
.sendButton:hover,
.showInputBtn:hover {
  filter: brightness(80%);
  background-color: var(--light);
}
.sendButton:hover > *,
.showInputBtn:hover > * {
  color: var(--font-secondary);
}

.btnIcon {
  color: var(--primary);
  width: 100%;
  height: 100%;
}

.inputText {
  position: relative;
  display: block;
  border-radius: 0.25rem;
  border-style: none;
  color: var(--primary);
  background-color: var(--light);
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0.5rem;
  text-align: left;
  font-family: var(--font-secondary);
  font-size: 1.15rem;
  outline-style: solid;
  outline-color: lightgrey;
  outline-width: 0.15rem;
}

.inputText::placeholder {
  color: var(--secondary);
}

input.inputText:hover {
  filter: brightness(0.9);
  outline-color: var(--secondary);
}

input.inputText:focus {
  filter: brightness(0.9);
  outline: none;
}
