.background {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    background: var(--menu-background);
    z-index: -1;
    // puts initial image off-screen
    background-position-x: 100vw;
    background-position-y: 100vh;
    background-repeat: no-repeat;
}

.background::after {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: inherit;
    // background-image: url(../assets/icons/logo-pizza.svg);
    background-size: 8rem 8rem;
    background-position: round;
    z-index: -3;
}

.background::before {
    display: block;
    position: absolute;
    content: '';
    margin: 0;
    margin-left: -4rem;
    margin-top: -4rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: inherit;
    // background-image: url(../assets/icons/logo-pizza.svg);
    background-size: 8rem 8rem;
    z-index: -2;
    filter: brightness(0.87);
}
