.container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  gap: 4rem;
  padding: 2rem;
  padding-top: 14vh;
  padding-bottom: 6rem;
  width: 100%;
  min-height: 50rem;
  justify-content: center;
  align-items: center;
}

.descriptionContainer {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem;
  border-width: 0.1rem;
  border-radius: 1rem;
  border-color: var(--light);
  border-style: solid;
  background-color: var(--menu-background);
  background: linear-gradient(
    125deg,
    hsl(292, 100%, 70%),
    hsl(263, 80%, 60%),
    hsl(214, 42%, 15%)
  );
  // background: linear-gradient(45deg, hsl(263, 35%, 20%), hsl(214, 42%, 15%));
  box-shadow: 0 0 30px 10px #f4daff, 0 0 80px 30px rgb(190, 39, 255),
    0 0 180px 20px hsl(270, 100%, 70%);

  // box-shadow: rgba(179, 102, 255, 0.25) 0px 54px 55px,
  //   rgba(179, 102, 255, 0.12) 0px -12px 30px,
  //   rgba(179, 102, 255, 0.12) 0px 4px 6px,
  //   rgba(179, 102, 255, 0.17) 0px 12px 13px,
  //   rgba(179, 102, 255, 0.09) 0px -3px 5px;
}

// .glow {
//   z-index: -1;
//   position: absolute;
//   left: 60px;
//   top: -30px;
//   width: 200px;
//   height: 200px;
//   border-radius: 50%;
//   background-color: #f4daff;
//   filter: blur(4px);
//   box-shadow: 0 0 60px 30px #f4daff, 0 0 100px 60px rgb(180, 0, 255),
//     0 0 140px 90px rgb(179, 102, 255);
// }

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 30rem;
  gap: 2rem;
}

.menusContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4rem;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}

.menuContainer {
  position: relative;
  display: block;
  width: 24rem;
  margin: 0;
  padding: 1rem;
  border-width: 0.1rem;
  border-radius: 1rem;
  border-color: var(--light);
  border-style: solid;
  background-color: var(--menu-background);
  background: linear-gradient(120deg, hsl(285, 40%, 20%), hsl(214, 42%, 15%));
  box-shadow: rgba(179, 102, 255, 0.07) 0px 15px 10px,
    rgba(180, 0, 255, 0.07) 0px 2px 4px, rgba(180, 0, 255, 0.07) 0px 4px 8px,
    rgba(180, 0, 255, 0.07) 0px 8px 16px, rgba(180, 0, 255, 0.07) 0px 16px 32px,
    rgba(180, 0, 255, 0.07) 0px 32px 64px;
}

.btnsContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  padding: 0;
  padding-top: 2rem;
}

.projectLink,
.projectLink a {
  color: var(--light);
  font-size: medium;
  text-align: center;
  width: 100%;
}

.btn,
.btnView {
  position: relative;
  min-height: 1rem;
  min-width: 3rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  text-align: center;
  font-family: var(--font-primary);
  border: 0;
  color: var(--light);
  border-color: var(--primary);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.1rem;
  overflow: hidden;
}
.btn {
  filter: brightness(90%);
  background-color: transparent;
}
.btn:hover {
  filter: brightness(110%);
}
.btn.active {
  background-color: var(--primary);
}

.btnView {
  color: var(--light);
  border-color: var(--light);
  background-color: transparent;
  filter: brightness(90%);
}
.btnView:hover {
  filter: brightness(110%);
}
.btnView.active {
  color: var(--menu-background);
  background-color: var(--light);
}

.logo {
  display: block;
  position: relative;
  width: 7rem;
  height: 7rem;
  bottom: -1rem;
  left: 0;
}

.title,
.appTitle {
  color: var(--light);
  text-align: center;
  margin: auto;
  margin-bottom: 2rem;
  font-size: x-large;
}

.appTitle {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--primary);
  margin: 0;
  font-size: 5rem;
  font-weight: bolder;
  align-self: center;
}

.appTitleContainer {
  background: -webkit-linear-gradient(
    hsl(0, 0%, 100%),
    hsl(263, 100%, 70%),
    hsl(192, 100%, 19%)
  );
  color: #f4daff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  color: var(--light);
  font-size: larger;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

.footerContainer {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-style: none;
  border-width: 0.1rem;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  padding: 1rem;
  background-color: var(--menu-background);
}

.footer {
  display: block;
  position: relative;
  width: 100%;
  color: var(--light);
  font-size: small;
  text-align: center;
}
.footer a {
  color: var(--light);
}

// Small screen
@media (max-width: 550px) {
  .menusContainer,
  .menuContainer,
  .titleContainer {
    width: 100%;
  }
}
