@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

:root {
  // Default colors
  --primary: #9900ff;
  --secondary: #560090;
  --background: #140021;
  --light: #fafafd;
  --slate: hsl(0, 0%, 80%);
  --menu-background: #191a1c;
  // Fonts
  --font-primary: "Oxygen", sans-serif;
  --font-secondary: "Roboto", sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: var(--primary);
  color: var(--light);
}

body {
  color: var(--primary);
  background-color: var(--background);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
