.totalContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: fixed;
  max-width: 50rem;
  width: 100%;
  align-self: center;
  margin: auto;
  padding: 0;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  bottom: 0;
  overflow: hidden;
  z-index: 200;
  pointer-events: none; // allows clicking underneathe the fade. cannot be in same div as child btns.
}

.fade {
  display: block;
  position: relative;
  height: 0.1rem;
  width: 100%;
  align-self: center;
  background-color: var(--light);
  box-shadow: 0 -0.25rem 0.75rem 0.75rem var(--light);
  padding: 0;
  margin: 0;
  margin-top: 2rem; // allows fade to peak above fold
}

.total {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-self: center;
  align-items: flex-end;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  color: var(--secondary);
  background-color: var(--light);
  border-top: 0.1rem solid var(--secondary);
  pointer-events: all;
}

.totalTitle {
  font-size: 1.25rem;
  margin: 0;
}

.totalPrice {
  font-size: 1.5rem;
  margin: auto;
  margin-right: 0;
}

.searchContainer {
  position: relative;
  display: block;
  margin: auto;
  margin-left: 0.5rem;
  padding: 0;
  width: 100%;
  height: max-content;
}

// Small screen
@media (max-width: 600px) {
  .searchContainer {
    margin: auto;
    width: 100%;
  }
  .total {
    flex-direction: column;
  }
  .totalContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// Medium screen
@media (max-width: 900px) {
  .totalContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: none;
  }
}
