.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: auto;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
}

.langChoiceContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: stretch;
  justify-content: center;
  color: var(--light);
  text-align: center;
}

.loaderContainer {
  transform: scale(1);
}

.loadingToast {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.loadingInstruction {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

.loaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}

.instructions h1,
.instructions h2 {
  margin-bottom: 0;
}

.btn {
  width: 100%;
  cursor: pointer;
  padding: 0.25rem;
  font-size: large;
  font-weight: bold;
}
.btn:disabled {
  cursor: not-allowed;
}

.title {
  color: var(--light);
  text-align: center;
  margin: auto;
  margin-bottom: 2rem;
  font-size: x-large;
}

.instrText {
  position: relative;
  display: flex;
  height: 5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  text-align: center;
  font-size: 3rem;
  color: var(--light);
  overflow: hidden;
  padding: 0;
}

.instructions {
  color: var(--light);
  height: fit-content;
  width: 100%;
  text-align: center;
}

.camContainer {
  width: 100%;
  height: 100%;
  background-color: hsl(226, 46%, 11%);
}

.camera {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  font-size: 6rem;
  color: var(--primary);
  text-align: center;
  padding: 2.5rem;
  font-family: var(--font-secondary);
  width: 100%;
  height: 100%;
  filter: opacity(50%);
}
.camera {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  line-height: normal;
}

.camera div {
  margin: auto;
  padding: 0;
}

.btnsContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
}

.apiMenuContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  color: wheat;
  gap: 0.5rem;
  font-size: 1.25rem;
  background-color: var(--menu-background);
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  filter: opacity(0.85);
}

.showKeysBtn {
  font-size: 1.25rem;
  align-self: flex-end;
  margin: 0;
  padding: 0;
}

.keysContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fileInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 12rem;
  width: 12rem;
  max-width: 15rem;
  padding: 0.25rem;
  margin: auto;
  border-radius: 0.5rem;
  border-color: var(--light);
  border-style: dashed;
  border-width: 0.2rem;
  font-size: 1rem;
  overflow: hidden;
  background-color: var(--menu-background);
  filter: brightness(90%);
}
.fileInputContainer:hover {
  filter: brightness(125%);
  background-color: var(--menu-background);
}

.fileInput {
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.inputButton {
  position: relative;
  display: block;
  width: 100%;
  max-width: 20rem;
  height: 10rem;
  background-color: var(--primary);
  border-radius: 0.5rem;
  border-width: 0;
  color: var(--light);
  text-align: center;
  font-weight: bold;
  font-family: var(--font-primary);
  font-size: 2.5rem;
  cursor: pointer;
  overflow: hidden;
}
.inputButton:disabled,
.inputButton:disabled:hover {
  color: grey;
  background-color: var(--secondary);
  cursor: not-allowed;
}
.inputButton:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: wheat;
  filter: brightness(0.9);
}

input.fileInput {
  width: 100%;
}
