.container {
  position: relative;
  display: flex;
  min-width: 4rem;
  width: 100%;
  margin: auto;
  margin-top: 2.5rem;
  overflow-x: auto;
  justify-content: center;
  flex-direction: row;
  gap: 0 2.5rem;
}

.iconContainer {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  border-style: none;
  cursor: pointer;
  text-decoration: none;
}

.icon {
  display: block;
  position: relative;
  margin: auto;
  padding: 0.75rem;
  border-radius: 1.5rem;
  width: 4rem;
  height: 4rem;
  background-color: hsl(0, 0%, 82%);
}

.icon:hover {
  background-color: hsl(0, 0%, 70%);
}

.title {
  color: var(--background);
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0;
  text-transform: capitalize;
}

.link {
  text-decoration: none;
}

// Small screen
@media (max-width: 900px) {
  .container {
    padding-left: 10%;
    padding-right: 10%;
  }
}
